@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300&family=Poppins:wght@300;500;900&display=swap');
@import "vars";

body {
    background-color: $bg;
    min-width: $page-width-min;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}

* {
    color: $fg;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
    margin: unset;
}

h1, h2, h3 {
    font-weight: 900;
}

h4, h5, h6 {
    font-weight: 500;
}
