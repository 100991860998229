@import "vars";

.page-header {
    padding: $padding-xl 0;
    background-color: $accent;
    transition: padding $tr-sm;

    @media screen and (min-width: $breakpoint-md) {
        padding: $padding-xxl 0;
    }
}
