@import "vars";

header.header {
    user-select: none;
    border-bottom: $border;

    .social {
        border-bottom: $border;

        .wrap {
            display: flex;
            justify-content: center;

            .social-link {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: $padding-sm $padding-md;
                border-left: $border;

                svg {
                    fill: $fg-dim;
                    transition: fill $tr-sm;
                }

                &:hover {
                    svg {
                        fill: $brand;
                    }
                }

                &:last-of-type {
                    border-right: $border;
                }
            }

            @media screen and (min-width: $breakpoint-sm) {
                justify-content: flex-end;
            }
        }
    }

    .navbar {
        @extend .wrap;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: $padding-md;
        padding-bottom: $padding-md;

        .brand {
            display: flex;
            align-items: center;

            svg {
                fill: $fg;
                margin-right: $padding-sm;
                transition: fill $tr-sm;
            }

            &:hover {
                svg {
                    fill: $brand;
                    animation-name: spin;
                    animation-duration: 1250ms;
                    animation-timing-function: cubic-bezier(.43, .84, .61, .14);
                    animation-iteration-count: infinite;
                    animation-play-state: running;
                }
            }
        }

        .toggle {
            appearance: none;
            padding: $padding-sm $padding-md;
            background: none;
            border: none;
            cursor: pointer;
            display: flex;

            svg {
                fill: $fg;
                transition: fill $tr-sm;
            }

            &.active, &:hover {
                svg {
                    fill: $fg-dim;
                }
            }
        }

        .navbar-links {
            display: flex;

            .navbar-link {
                padding: $padding-sm $padding-md;
            }
        }
    }

    .navbar-dropdown {
        transition: height $tr-sm;

        .navbar-dropdown-menu {
            display: flex;
            flex-direction: column;
            padding-top: $padding-md;
            padding-bottom: $padding-md;
            border-top: $border;

            .navbar-link {
                padding: $padding-sm;
                text-align: center;
            }
        }
    }

    .navbar-link {
        color: $fg;
        transition: color $tr-sm;

        &:hover {
            color: $fg-dim;
        }

        &.active {
            color: $brand;

            &:hover {
                color: $brand-dim;
            }
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
