// colors
$bg: #060606;
$fg: #f3f3f3;
$fg-dim: #909090;
$brand: #fad306;
$brand-dim: darken($brand, 20%);
$accent: lighten($bg, 5%);
$accent-active: lighten($bg, 2%);

// padding
$padding-sm: 0.375rem;
$padding-md: $padding-sm * 2;
$padding-lg: 1rem;
$padding-xl: $padding-lg * 2;
$padding-xxl: $padding-xl * 2;

// borders
$accent-width: 0.25rem;
$border-width: 1px;
$border: $border-width solid $accent;
$border-radius: 0.5rem;

// font sizes
$h1: 3rem;
$h2: 2rem;
$h3: 1.75rem;
$h4: 1.5rem;
$h5: 1.25rem;
$h6: 1.125rem;
$h7: 1rem;
$h8: 0.9rem;

// breakpoints
$page-width-min: 325px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1092px;
$max-width: 75rem;

// transition timings
$tr-sm: 250ms ease-in-out;
$tr-md: 500ms ease-in-out;
$tr-lg: 750ms ease-in-out;

// global classes
.wrap {
    padding-left: $padding-xl;
    padding-right: $padding-xl;
    max-width: $max-width;
    margin: 0 auto;
    transition: padding $tr-sm;

    &.vertical {
        padding-top: $padding-xl;
        padding-bottom: $padding-xl;
    }

    @media screen {
        @media (min-width: $breakpoint-md) {
            padding-left: $padding-xxl;
            padding-right: $padding-xxl;

            &.vertical {
                padding-top: $padding-xxl;
                padding-bottom: $padding-xxl;
            }
        }

        @media (min-width: $breakpoint-xl) {
            &.vertical.large {
                padding-top: $padding-xxl * 2;
                padding-bottom: $padding-xxl * 2;
            }
        }
    }
}

