@import "vars";

footer.footer {
    margin-top: $padding-xxl; // temporary
    border-top: $border;
    padding: $padding-xl 0;
    transition: padding $tr-sm;

    .wrap {
        display: flex;
        flex-direction: column;

        .footer-section {

            &:not(:last-of-type) {
                margin-bottom: $padding-md;
            }
        }
    }


    @media screen and (min-width: $breakpoint-md) {
        padding: $padding-xxl 0;

    }
}
