@import "vars";

.icon {
    &-s {
        width: $h6;
        height: $h6;
    }

    &-m {
        width: $h4;
        height: $h4;
    }

    &-l {
        width: $h2;
        height: $h2;
    }
}
